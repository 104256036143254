.contacts-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #f7f7f7;
    padding: 20px 15px;

    width: 100%;

}

.contats-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.contact-header-title {
    display: flex;
    align-items: center;
    height: 20px;
    justify-content: flex-start;
    align-items: center;
}

.contact-header-title span {
    font-size: 14px;
    font-family: "SF-Pro-Rounded";
    color: #404040;
    display: flex;
    margin-left: 10px;
}

.colse-contact {
    display: flex;
}

.contact-filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 28px;
    padding-bottom: 9px;
    width: 100% !important;
    min-height: 65px;

}

.contact-filter-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8px;
    border-radius: 5px;
    background-color: #F0F0F0;
    padding-top: 8px;
    min-width: 77px;
    height: 50px;
}

.contact-filter-item:not(:first-child) {
    margin-left: 10px;
}

.contact-filter-item.active-f {
    background-color: #DDDDDD;
}

.filter-title {
    font-family: "SF-Pro-Rounded-med";
    font-size: 12px;
    color: #5D5D5D;
    display: flex;
    letter-spacing: 0em;
}

.filter-count {
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    letter-spacing: 0em;

    color: #5D5D5D;
    margin-top: 3px;
    display: flex;
}

.contact-icon {
    display: flex;
    margin-left: 10px;
}

.container-search {
    width: 100% !important;
    margin-top: 10px;
    max-height: 30px;
}

.container-search svg {
    left: 30px !important;
}

.container-search input {
    background-color: #f7f7f7 !important;
    padding-left: 55px;
}

.contact-item {
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 5px;
    min-height: 50px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    margin-top: 10px;
    padding-right: 10px;
}

.contact-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.contact-img {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #F0F0F0;
}

.contact-img img {
    display: flex;
    min-width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #F0F0F0;
}

.contact-detail {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-items: flex-start;
    justify-content: space-between;
    height: 37px;
}

.contact-name {
    letter-spacing: 0em;
    font-size: 12px;
    outline: none;
    border: none;
    color: #5D5D5D;
    font-family: "SF-PRO-Rounded-med";
}

.contact-numb {
    color: #8E8E8E;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
    letter-spacing: 0em;
}

.contact-comp,
.contact-role {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-items: center;
    justify-content: space-between;
    height: 32px;
}

.contact-filter-item.edit-f:not(:first-child) {
    margin-left: 5px !important;
}

.edit-f {
    min-width: 52px !important;
    max-width: 52px !important;
    padding: 0px !important;
    height: 30px !important;
    justify-content: center !important;
    align-items: center !important;
}

.contact-detail-edit {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.contacts-list {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.save-contact {
    background-color: #CEFFD0;
    width: 42px;
    height: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "SF-PRO-Rounded";
    color: #404040;
}

.contact-edit {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
}

.cancel-edit {
    display: flex;
    margin-left: 10px;
    cursor: pointer;
}

.role-edit,
.comp-edit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.col-res {
    flex-direction: column !important;
    align-items: flex-start;
    margin-left: 5px;
}

.inhj .contact-item .contact-edit {
    display: flex;
    position: absolute;
    right: 10px;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
}